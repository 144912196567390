import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
  },
  {
    path: '/music',
    name: 'music',
    component: () => import(/* webpackChunkName: "music" */ '../views/Music.vue')
  },
  
  {
    path: '/food',
    name: 'food',
    component: () => import(/* webpackChunkName: "food" */ '../views/Food.vue')
  },
  {
    path: '/films',
    name: 'films',
    component: () => import(/* webpackChunkName: "films" */ '../views/Films.vue')
  },
  
  {
    path: '/web',
    name: 'web',
    component: () => import(/* webpackChunkName: "web" */ '../views/Web.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
})

export default router
