<template>
    <div class="footer d-flex align-items-center justify-content-center">
        <div class="">
            <span class="leyenda_footer">B312 Studio | {{fecha}} </span>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            fecha: '',
        }        
    },
    created(){
        var date = new Date();
        this.fecha = date.getFullYear();
    }

}
</script>

<style>
    .footer{
        bottom: 0;
        width: 100%;
        height:100px;
    }

    .footer .leyenda_footer{
        color: var(--hueso);
        font-family: rubik,sans-serif;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
    }
</style>