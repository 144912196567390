<template>
    <div class="navbar">
	
		<div class="logo mx-2">
			<a href="/" class="mb-0 "><img src="../assets/images/logo.svg" alt=""></a>
		</div>		
		
		<ul class="links">
			<li  v-on:click="cerrar()" class=" mx-5 mx-md-4 mx-lg-5"><router-link class="enlace" to="/music">Music</router-link> </li>
			<li  v-on:click="cerrar()" class=" mx-5 mx-md-4 mx-lg-5"><router-link class="enlace" to="/food">Food</router-link> </li>
			<li  v-on:click="cerrar()" class=" mx-5 mx-md-4 mx-lg-5"><router-link class="enlace" to="/films">Films</router-link> </li>
			<li  v-on:click="cerrar()" class=" mx-5 mx-md-4 mx-lg-5"><router-link class="enlace" to="/web">Web</router-link> </li>
			<li  v-on:click="cerrar()" class=" mx-5 mx-md-4 mx-lg-5"><router-link class="enlace" to="/contact">Contact</router-link> </li>
			<li class=" mx-5 mx-md-4 mx-lg-5 mx-lg-0">
                <div class="cont_redes d-flex ">
				    <div class=""><a class="enlace" target="_blank" href="https://instagram.com/b312studio"><i class="icono fa-brands fa-instagram"></i></a></div>
			    </div>
            </li>            
		</ul>
		
	
		
		<div class="toggle mx-2" v-on:click="abrir()">
			<div class="line1"></div>
			<div class="line2"></div>
			<div class="line3"></div>
		</div>
		
	</div>

    
</template>

<script>
export default {
   
     methods: {
      abrir () {
        const navbar = document.querySelector('.navbar')
          navbar.querySelector('.toggle').addEventListener('click',()=>{          
          navbar.classList.toggle('collapsed')          
        })

        window.addEventListener('scroll',(e)=>{
          let windowY = window.pageYOffset
          let navbarHeight = document.querySelector('.navbar').offsetHeight
          if(windowY>navbarHeight) navbar.classList.add('sticky')
          else navbar.classList.remove('sticky')
        })
       },
	   cerrar(){
		   const navbar = document.querySelector('.navbar')
		    navbar.classList.toggle('collapsed') 

	   }
    }
}
</script>

<style>

    /* Menu */

.navbar {
     position: fixed;
     top: 0;
	 background: var(--negro);
	 color: var(--hueso);
	 width: 100vw !important;
	 height: 4rem;
	 display: flex;
	 align-items: center;
     justify-content: center;
     padding: 0 1rem;
     z-index: 2;
}
 .navbar .logo {
	 cursor: pointer;
     /* position: absolute; */
    width: 4rem;
}

.navbar .logo a{
    width: 100%;
}

.navbar .logo a img{
    width: 100%;
}

.cont_redes .icono{
	color: var(--blanco);
}

ul {
	padding: 0;
    margin: 0 ;
}

 .navbar ul.links {
	margin-left: auto;
	/* display: flex; */
	transition: 0.4s ease all, 0s ease background;
    opacity: 0;
}

.enlace {
	 text-decoration: none;
	 color: var(--hueso) !important;
	 text-transform: uppercase;
	 /* padding: 0 15px; */
	 height: 65px;
	 display: flex;
	 align-items: center;
	 transition: 0.4s ease all, 0s ease margin;
	 font-size: 2rem;
}

.enlace:hover,
.icono:hover {
	 color: var(--amarillo) !important;
	 cursor: pointer;
}

.navbar.sticky {
	 position: fixed;
	 z-index: 40;
}
 .navbar.sticky ~ main {
	 position: relative;
	 z-index: 20;
	 top: 65px;
}

 @media screen and (max-width: 768px) {
	 .navbar .right {
		 height: 0;
		 overflow: hidden;
	}
	 .toggle {
		 position: absolute;
		 top: 32.5px;
		 right: 20px;
		 z-index: 99;
		 transform: translateY(-50%);
		 cursor: pointer;
		 width: 40px;
		 height: 40px;
		 display: flex;
		 flex-direction: column;
		 justify-content: center;
		 align-items: center;
	}
	 .toggle > * {
		 width: 80%;
		 height: 2px;
		 background: var(--hueso);
		 margin: 3px 0;
	}

    .navbar.collapsed{
        z-index: 2;
         width: 100vw;
    }
	 .navbar.collapsed .toggle .line1 {
		 transform: rotate(-45deg) translate(-4px, 5px);
	}
	 .navbar.collapsed .toggle .line2 {
		 opacity: 0;
	}
	 .navbar.collapsed .toggle .line3 {
		 transform: rotate(45deg) translate(-5px, -6px);
	}
	 .navbar.collapsed .logo {
		 position: absolute;
		 z-index: 44;
	}
	 .navbar .links {
		 /* position: fixed; */
		 background: var(--negro);
		 z-index: 40;
		 top: 0;
		 left: -100%;
		 flex-direction: column;
		 width: 100vw;
		 height: 100vh;
         opacity: 0;
		 display: none;
	}
	 .navbar .links li:first-child {
		 margin-top: 6rem;
	}
	 .navbar.collapsed .links {
		 left: 0;
         opacity: 1;
	 	display: flex;
	}

	 .enlace {
		 text-align: left;
	}

	.wallet{
		word-break: break-all;
		margin-right: 1rem;
	}

	.cont_redes{
		margin-top: 4rem;
	}

	.cont_redes .enlace .icono{
		font-size: 2rem;
	}

	 .navbar.collapsed .links li {
		 width: 100%;


	}
	 .enlace:hover {
		 color: var(--amarillo);
	}
	 .navbar.collapsed .right {
		 position: fixed;
		 width: 45vw;
		 text-align: center;
		 height: auto;
		 bottom: 40px;
		 left: 22.5vw;
		 transform: translateX(-50%);
		 z-index: 45;
	}
	 .navbar.collapsed ~ main {
		 filter: blur(1px);
		 opacity: 0.8;
	}
	 .navbar.sticky {
		 
		 z-index: 100;
         top: 0;
	}
}
</style>