<template>

  <div id="app">
    <Menu/>
    <router-view/>
    <Footer/>
    
  </div>
</template>

<script>

   import Menu from '@/components/Menu.vue'   
  import Footer from '@/components/Footer.vue'

    export default {
      components: {
        Menu, Footer
      },
      methods: {
        pointer(){
          
        }
      },
    }
</script>

<style>
  :root{
    --negro:#161616;
    --rojo: #8f0000;
    --vino: ;
    --hueso: #f5f5f5; 
    --amarillo: #fad201;
  }

  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }

   li{
     list-style: none;
   } 

   a{
     text-decoration: none;
   }
  
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  /* Loader */

  #preloader{
    background: var(--negro)  url(./assets/images/loader_b312.gif) no-repeat center center;
    background-size: 25%;
    top: 0;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 999;
  }

  /* Ruido */
  .noise::before{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    content: "";
    opacity: 0.05;
    z-index: 1000;
    pointer-events: none;
    background: url(./assets/images/noise.gif);
  }



  .container{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100vh;
  }

  body{
    background-color: var(--negro);
    color: var(--hueso);
  } 

  .titulo_section{
    font-size: 4rem;
    font-family: rubik,sans-serif;
    font-weight: 700;
    font-style: medium;
    text-transform: uppercase;
    color: var(--hueso);
    /* word-break: break-all; */
    width: 100%;
    text-align: justify;
    padding: 0 1rem ;
  }

  .texto,
  .texto a,
  .subtitulo,
  .titulo,
  .titulo span a{
    color: var(--hueso);
  }

  .texto span,
  .subtitulo span
  {
    color: var(--amarillo);
  }

  .texto a:hover,
  span a:hover{
    color: var(--amarillo);
  }

  .subtitulo{
    font-size: 1.5rem ;
  }

  body::-webkit-scrollbar {
    width: 1em;
}
 
body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: var(--rojo);
  border-radius:1rem;
  outline: none;
}

</style>
