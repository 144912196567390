<template>
    <section id="section_home" class="cont_servicios align-self-center d-flex flex-column flex-lg-row flex-md-column justify-content-center align-items-center">
        <div class="servicio d-flex align-items-center">
          <div class="contenido_servicio d-flex flex-column align-items-center justify-content-center">
            <div class="top_section mx-auto text-center w-100 ">
              <router-link to="/music">
                <h1 class="titulo">
                  Music <br />
                  Videos
                </h1> 
              </router-link>
            </div>
            <router-link to="/music">
              <video class=" video_servicio" autoplay muted loop src="../assets/videos/music_video_hero.mp4" type="video/mp4">
                Your browser does not support HTML5 video.
              </video>
          </router-link>
          </div>
          
        </div>

        <div class="servicio d-flex align-items-center">
          <div class="contenido_servicio d-flex flex-column align-items-center justify-content-center">
            <div class="top_section mx-auto text-center w-100 ">
               <router-link to="/food">
                <h1 class="titulo">
                  Food <br />
                  P*rn
                </h1>
              </router-link>
            </div>
            <router-link to="/food">
              <video class=" video_servicio" autoplay muted loop src="../assets/videos/food_video_hero.mp4" type="video/mp4">
                Your browser does not support HTML5 video.
              </video>
            </router-link>
          </div>
        </div>

        <div class="servicio d-flex align-items-center">
          <div class="contenido_servicio d-flex flex-column align-items-center justify-content-center">
            <div class="top_section mx-auto text-center w-100 py-4">
               <router-link to="/films">
                <h1 class="titulo">
                  Films 
                  <br>
                </h1>
              </router-link>
            </div>
            <router-link to="/films">
              <video class=" video_servicio" autoplay muted loop src="../assets/videos/retrato_video_hero.mp4" type="video/mp4">
                Your browser does not support HTML5 video.
              </video>
              </router-link>
            </div>
        </div>

        <div class="servicio d-flex align-items-center">
            <div class="contenido_servicio d-flex flex-column align-items-center justify-content-center">
                <div class="top_section mx-auto text-center w-100 ">
                  <router-link to="/web">
                    <h1 class="titulo">
                        Web<br>
                        Development 
                    </h1>
                  </router-link>
                </div>
                <router-link to="/web">
                 <video class=" video_servicio" autoplay muted loop src="../assets/videos/program_video_hero.mp4" type="video/mp4">
              Your browser does not support HTML5 video.
              </video>
            </router-link>
            </div>
        </div>
      </section>
</template>

<script>
export default {
    
}
</script>

<style>

  #section_home{
    margin-top:5rem;
  }

 .cont_servicios {
    width: 100%;
    margin: 0;
    padding: 0;
    height: auto;
    position: relative;   
    background: var(--negro); 
    overflow: hidden;
  }

 .servicio{
    position: relative;
    width: 100%;
    height: 80vh;
    border-bottom:6px solid var(--rojo);   
    box-sizing: border-box;
    transition: 0.5s;
    text-align: center;
    /* overflow: hidden; */
  }

  .servicio:nth-child(4){
    border: none;
  }

  .servicio .contenido_servicio {
    width: 100%;
    height: 100%;
  }
  
  .servicio .contenido_servicio .top_section{
       display: flex;
      position: absolute;
      justify-content: center;
      align-items: center;
      opacity: 1;
      z-index: 1;
      /* border: 1px solid red; */
  }

  .servicio .contenido_servicio .top_section .titulo {
    font-size: 2.5rem;
     font-family: rubik,sans-serif;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    color: var(--hueso);
    word-break: break-all;
  }

   .servicio .contenido_servicio .video_servicio{
      object-fit: cover;
      width:100%;
      height:100%;
      opacity: 1;
   }

    .servicio .contenido_servicio .video_servicio:hover{
      opacity: 1;
    }
 
  
  .servicio:hover .top_section .titulo {
    transition: color 0.8s cubic-bezier(0.19, 1, 0.22, 1);
    color: var(--amarillo);
  }
</style>